@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --light-green: 120.39 60.78% 50%;
    --dark-green: 161.58 83.47% 23.73%;
    --border-green: 161.58 83.47% 23.73%;
    --chart-bars: 161.58 83.47% 23.73% / 0.8;
    --energy-bars: 120.39 60.78% 50% / 0.5;
    --yellow: 43.26 96.41% 56.27%;
    --nav-background: 0 0% 100%;
    --nav-background-muted: 0 0% 94%;
    --background: 0 0% 100%;
    --foreground: 0 0% 20%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 20%;
    --popover: 162 100% 100%;
    --popover-foreground: 162 100% 10%;
    --primary: 161.58 83.47% 23.73%;
    --primary-foreground: 0 0% 100%;
    --secondary: 0 0% 89.8%;
    --secondary-foreground: 0 0% 20%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 0 0% 94%;
    --accent-foreground: 162 5% 15%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 162 5% 100%;
    --border: 0 0% 88%;
    --input: 0 0% 88%;
    --ring: 161.58 83.47% 23.73%;
    --charging: 120.39 60.78% 50%;
    --preparing: 220 70% 50%;
    --online: 161.58 83.47% 23.73%;
    --offline: 0 0% 79.61%;
    --error: 0 100% 50%;
    --chart-1: 161.58 83.47% 23.73%;
    --chart-2: 120.39 60.78% 50%;
    --chart-3: 137 55% 15%;
    --chart-4: 141 40% 9%;
    --chart-line: 0 0% 90%;
    --radius: 0.5rem;
    --sidebar-full: 270px;
  }

  .dark {
    --border-green: 120.39 60.78% 50%;
    --nav-background: 0 0.76% 25.69%;
    --nav-background-muted: 0 1.85% 21.18%;
    --chart-bars: 120.39 60.78% 50% / 0.6;
    --energy-bars: 120.39 60.78% 50% / 0.6;
    --background: 0 0% 5%;
    --foreground: 0 5% 90%;
    --card: 0 0.76% 25.69%;
    --card-foreground: 0 5% 90%;
    --popover: 0 0% 5%;
    --popover-foreground: 162 5% 90%;
    --primary: 120.39 60.78% 50%;
    --primary-foreground: 0 0% 20%;
    --secondary: 120 40% 20%;
    --secondary-foreground: 0 0% 100%;
    --muted: 123 48% 26%;
    --muted-foreground: 0 5% 60%;
    --accent: 0 0% 35%;
    --accent-foreground: 162 5% 90%;
    --destructive: 0 100% 40%;
    --destructive-foreground: 162 5% 90%;
    --border: 0 0% 35%;
    --input: 0 0% 55%;
    --ring: 120.39 60.78% 50%;
    --preparing: 220 83% 66%;
    --offline: 0 0% 50%;
    --chart-1: 120.39 60.78% 50%;
    --chart-2: 120.39 60.78% 50%;
    --chart-3: 137 55% 15%;
    --chart-4: 141 40% 9%;
    --chart-line: 0 0% 40%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply box-border bg-navBackground text-foreground;
  }
}

@layer utilities {
  .min-h-screen {
    min-height: 100vh;
    /* Fallback */
    min-height: 100dvh;
  }

  .h-screen {
    height: 100vh;
    /* Fallback */
    height: 100dvh;
  }
}
